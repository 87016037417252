import AboutComponent from '../components/about/about-component';
import { IAboutPage } from '../components/about/about.interface';
import React from 'react';
import Seo from '../components/Seo';
import { StyledContent } from '../components/layout/layout';
import { graphql } from 'gatsby';
import { IRecipe } from '../components/recipes/recipe.interface';
import { H1 } from '../components/shared/typography';

interface Props {
  data: {
    allContentfulOpskrift: {
      nodes: IRecipe[];
    };
  };
}

const Opskrifter: React.FC<Props> = ({ data }: Props) => {

  return (
    <StyledContent>
      <Seo
        title={'Alle Opskrifter'}
        image={'https://www.mansted-wine.dk/logo/mansted-wine-logo.jpg'}
        description={"Alle opskrifter hos manstedwine"}
      />
      <H1>Alle opskrifter</H1>
      {data.allContentfulOpskrift.nodes.map(r => (
        <a href={`/opskrifter/${r.overskrift.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}/`}>{r.overskrift} <br/></a>
      ))}
    </StyledContent>

  );
};

export default Opskrifter

export const AllRecipeQuery = graphql`
    query  {
        allContentfulOpskrift {
        nodes {
            overskrift
        }
    }
}    
`;
